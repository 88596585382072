export default {
  initLat: 45.52,
  initLon: -122.682,
  // The boundaries for setting current location
  bounds: {
    top: 45.96,
    bottom: 44.68,
    left: -123.8,
    right: -121.5,
  },
};
