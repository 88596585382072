import styled, { keyframes } from 'styled-components';
import { PHONE_BREAK, Z_INDEX_TOP } from '#/shared/constants';
import SvgUrgentHelpCircle from '#/shared/components/GeneratedIcons/trimet/UrgentHelpCircle';
import BaseInfoIcon from '#/shared/components/GeneratedIcons/trimet/Info';
import CircleProgressbarBase from '#/shared/components/CircleProgressbar';
import { ClearButton } from '#/shared/components/Styled';

export const CloseButton = styled(ClearButton)`
  position: relative;
  width: 32px;
  height: 32px;
  margin: 0.6rem 0.75rem;
  border-radius: 100%;
  cursor: pointer;

  // stack direct child elements and center them relative to the parent
  & > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const enter = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const exit = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
`;

export const SoftToastWrapper = styled.div`
  animation: ${({ visible }) => (visible ? enter : exit)} 0.5s ease-out both;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.warningFontColor};
  border-radius: 8px;
  color: ${props => props.theme.buttonText2};
  justify-content: space-between;
  box-shadow: 0 1px 6px ${props => props.theme.softToastShadowColor};
  z-index: ${Z_INDEX_TOP};
  width: 100%;
  overflow: hidden;

  // on desktop let the content determine the width of the toast
  @media (min-width: ${PHONE_BREAK}) {
    width: initial;
  }
`;

const ICON_SIZE = { width: 20, height: 20 };

export const IconWrapper = styled.div`
  min-width: 20px;
  margin: 0 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ErrorIcon = styled(SvgUrgentHelpCircle).attrs(ICON_SIZE)``;

export const InfoIcon = styled(BaseInfoIcon).attrs(({ theme }) => ({
  ...ICON_SIZE,
  fill: theme.infoColor,
}))``;

export const TextWrapper = styled.div`
  position: relative;
  display: flex;
  padding: 0.75em 0;
  flex: 1 1 auto;
`;

export const CircleProgressbar = styled(CircleProgressbarBase).attrs(
  ({ theme }) => ({
    frontCircleColor: theme.menuFooterBg,
    backCircleColor: theme.white,
  })
)``;

export const ToasterWrapper = styled.div`
  height: 0;
  width: 0;
`;
