import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { developerApi } from '#/services/developerApi';
import { mapsApi } from '#/services/mapsApi';
import { NODE_ENVS } from '#/shared/constants';
import rootReducer from './rootReducer';
import rtkQueryErrorHandler from './middleware/rtk-query-error-handler';

const middlewareList = [
  developerApi.middleware,
  mapsApi.middleware,
  rtkQueryErrorHandler,
];

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(middlewareList),
  devTools: process.env.NODE_ENV !== NODE_ENVS.PROD,
});

// Required for refetchOnFocus/refetchOnReconnect behaviors
setupListeners(store.dispatch);

export default store;
