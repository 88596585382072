import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { utils as vehicleOverlayUtils } from '@opentripplanner/transit-vehicle-overlay';
import { parseSignVanityName } from '#/shared/utils/transit';
import {
  routeNumberToVehicleColors,
  countAlertsByRoute,
  mapRoute,
} from '#/transit-tracker/utils/routes';
import { ARRIVALS_REFRESH_INTERVAL } from '#/shared/constants';
import {
  mapAndSortArrivals,
  mapSignsForStop,
  mapStopToOtp,
} from '#/transit-tracker/utils/arrivals';

export const api = {
  host: 'https://developer.trimet.org',
  paths: {
    // TODO: finish building out the developerApi; all endpoints are setup
    //  and in use here and the migration is mostly complete, but the are
    //  still a couple of uses of `/arrivals` that use the old query approach.
    //  Once those go away this `api` object no longer needs to be exported
    alerts: '/ws/v2/alerts',
    arrivals: '/ws/v2/arrivals',
    vehicles: '/ws/v2/vehicles/hasTripId/true',
    stops: '/ws/v1/stops',
    routeConfig: '/ws/V1/routeConfig',
  },
  appId: '8CBD14D520C6026CC7EEE56A9',
};

const BASE_QUERY_PARAMS = {
  json: true,
  appId: api.appId,
};

export const DEFAULT_ARRIVALS_QUERY = {
  ...BASE_QUERY_PARAMS,
  minutes: 0,
  arrivals: 1,
};

export const developerApi = createApi({
  reducerPath: 'developerApi',
  baseQuery: fetchBaseQuery({ baseUrl: api.host }),
  refetchOnReconnect: true,
  endpoints: build => ({
    getRealtimeVehicles: build.query({
      // query only takes a single arg so if you have more than one pass it as an object
      query: rtvParams => ({
        url: api.paths.vehicles,
        params: { ...BASE_QUERY_PARAMS, ...rtvParams },
      }),
      // value in seconds to keep unsubscribed query in the cache
      keepUnusedDataFor: 5,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: response => {
        return vehicleOverlayUtils
          .convertAltData(response)
          .map(parsedVehicle => {
            const originalVehicle = response.resultSet.vehicle.find(
              o => String(o.vehicleID) === String(parsedVehicle.vehicleId)
            );

            return {
              ...parsedVehicle,
              ...routeNumberToVehicleColors(originalVehicle.routeNumber),
              lastLocId: originalVehicle.lastLocID,
              nextLocId: originalVehicle.nextLocID,

              // TODO: this should be handled in OTP-UI's `triMetRouteToShortName`
              routeShortName:
                parsedVehicle.routeId === '2'
                  ? 'FX2'
                  : parsedVehicle.routeShortName,

              signMessage: originalVehicle.signMessageLong
                ? parseSignVanityName(
                    originalVehicle.signMessageLong,
                    originalVehicle.routeNumber
                  )
                : null,
            };
          });
      },
    }),
    getAlerts: build.query({
      query: () => ({
        url: api.paths.alerts,
        params: BASE_QUERY_PARAMS,
      }),
      // value in seconds to keep unsubscribed query in the cache
      keepUnusedDataFor: 60,
      transformResponse: response => {
        const alerts = response.resultSet.alert || [];
        const systemWideAlerts = alerts.filter(a => a.system_wide_flag);
        return {
          alertCountsByRoute: countAlertsByRoute(alerts),
          hasSystemWideAlert: systemWideAlerts && systemWideAlerts.length > 0,
          systemWideAlerts,
        };
      },
    }),
    getRouteAlerts: build.query({
      // query only takes a single arg so if you have more than one pass it as an object
      query: routeId => ({
        url: api.paths.alerts,
        params: {
          ...BASE_QUERY_PARAMS,
          route: routeId,
        },
      }),
      // value in seconds to keep unsubscribed query in the cache
      keepUnusedDataFor: 60,
      transformResponse: response => {
        const alerts =
          (response.resultSet.alert &&
            response.resultSet.alert.filter(a => !a.system_wide_flag)) ||
          [];
        return {
          routeAlerts: {
            alerts,
          },
        };
      },
    }),
    getStopAlerts: build.query({
      // query only takes a single arg so if you have more than one pass it as an object
      query: locIDs => ({
        url: api.paths.alerts,
        params: {
          ...BASE_QUERY_PARAMS,
          locIDs,
        },
      }),
      // value in seconds to keep unsubscribed query in the cache
      keepUnusedDataFor: 60,
      transformResponse: response => {
        const alerts =
          (response.resultSet.alert &&
            response.resultSet.alert.filter(a => !a.system_wide_flag)) ||
          [];
        return {
          stopAlerts: {
            alerts,
          },
        };
      },
    }),
    getArrivals: build.query({
      query: arrivalsParams => ({
        url: api.paths.arrivals,
        params: {
          ...DEFAULT_ARRIVALS_QUERY,
          ...arrivalsParams,
        },
      }),
      keepUnusedDataFor: ARRIVALS_REFRESH_INTERVAL / 1000,
      transformResponse: response => response.resultSet.arrival,
    }),
    getStopArrivals: build.query({
      query: stopId => ({
        url: api.paths.arrivals,
        params: {
          ...DEFAULT_ARRIVALS_QUERY,
          arrivals: 3,
          detailLevel: 2,
          locIDs: stopId || '',
        },
      }),
      keepUnusedDataFor: ARRIVALS_REFRESH_INTERVAL / 1000,

      transformResponse: response => {
        const { resultSet: responseData } = response;
        const rawStop = responseData?.location?.[0];
        if (!rawStop) return null;

        const stop = mapStopToOtp(rawStop);
        const {
          arrival: arrivals,
          detour: alerts,
          route: routes,
        } = responseData;

        const signs = arrivals
          ? mapSignsForStop(
              mapAndSortArrivals(arrivals, [stop], routes),
              routes,
              alerts
            )
          : {};

        const routeLookup = routes?.reduce(
          (lookup, route) => ({
            ...lookup,
            [route.id]: route,
          }),
          {}
        );

        return {
          stop,
          signs,
          routes: routeLookup,
          routeIds: routeLookup && Object.keys(routeLookup),
        };
      },
    }),
    getMapStops: build.query({
      // query only takes a single arg so if you have more than one pass it as an object
      query: ({ bbox }) => ({
        url: api.paths.stops,
        params: {
          ...BASE_QUERY_PARAMS,
          showRoutes: true,
          bbox:
            bbox ||
            // full Portland Metro Area
            '-123.1555,45.2594,-122.3205,45.6395',
        },
      }),
      // value in seconds to keep unsubscribed query in the cache
      keepUnusedDataFor: 10,
      transformResponse: response => {
        const stops = response.resultSet.location || [];
        return stops.map(stop => mapStopToOtp(stop));
      },
    }),
    getNearbyStops: build.query({
      // query only takes a single arg so if you have more than one pass it as an object
      query: nearbyStopsParams => ({
        url: api.paths.stops,
        params: {
          ...BASE_QUERY_PARAMS,
          ...nearbyStopsParams,
        },
      }),
      // value in seconds to keep unsubscribed query in the cache
      keepUnusedDataFor: 10,
      transformResponse: response => {
        return response.resultSet.location || [];
      },
    }),
    getRouteBasics: build.query({
      query: () => ({
        url: api.paths.routeConfig,
        params: BASE_QUERY_PARAMS,
      }),
      keepUnusedDataFor: 3600,
      transformResponse: response =>
        response.resultSet.route?.reduce(
          (routeLookup, routeData) => ({
            ...routeLookup,
            [routeData.id]: mapRoute(routeData),
          }),
          {}
        ),
    }),
    getRouteDetails: build.query({
      query: routeDetailParams => ({
        url: api.paths.routeConfig,
        params: {
          ...BASE_QUERY_PARAMS,
          dir: true,
          ...routeDetailParams,
        },
      }),
      keepUnusedDataFor: 5,
      transformResponse: response => mapRoute(response.resultSet.route?.[0]),
    }),
    getUniqueStopIdsByRoutes: build.query({
      query: routeDetailParams => ({
        url: api.paths.routeConfig,
        params: {
          ...BASE_QUERY_PARAMS,
          dir: true,
          ...routeDetailParams,
        },
      }),
      keepUnusedDataFor: 10,
      transformResponse: response => {
        const uniqueLocids = new Set(
          response.resultSet.route.flatMap(route =>
            route.dir.flatMap(dir =>
              dir.stop.map(stop => stop.locid.toString())
            )
          )
        );

        return Array.from(uniqueLocids);
      },
    }),
  }),
});

export const {
  useGetRealtimeVehiclesQuery,
  useGetAlertsQuery,
  useGetRouteAlertsQuery,
  useGetStopAlertsQuery,
  useGetArrivalsQuery,
  useGetStopArrivalsQuery,
  useGetMapStopsQuery,
  useGetNearbyStopsQuery,
  useGetRouteBasicsQuery,
  useGetRouteDetailsQuery,
  useGetUniqueStopIdsByRoutesQuery,
} = developerApi;
