import { createBrowserHistory } from 'history';
import queryString from 'query-string';

// history can't be used in SSR because it references browser objects
const history = typeof window !== 'undefined' ? createBrowserHistory() : null;

/**
 * Removes the supplied querystring parameter from the current URL without
 * reloading the page
 *
 * @param {string} paramToClear - Key for the querystring param to be removed
 * from the URL
 */
export const clearQueryParam = paramToClear => {
  if (!history) {
    return;
  }

  const { location } = history;
  const queryParams = queryString.parse(location.search);

  if (!(paramToClear in queryParams)) {
    return;
  }

  delete queryParams[paramToClear];
  history.replace({
    ...location,
    search: queryString.stringify(queryParams),
  });
};

/**
 * Convert path into regex that matches path names created from it
 *
 * @param path {string} Path for application route
 * @param prefix {string} Prefix used for all application paths, as set in the
 * Gatsby config
 * @return {RegExp}
 */
export const pathToRegex = (path, prefix) => {
  let uriRegex = '';

  // break the uri at forward slashes are replace the pieces that are
  // parameter or glob elements with comparable regex
  path
    .replace(/(^\/+|\/+$)/g, '') // strip leading & trailing slashes
    .split('/')
    .forEach((segment, ix, segmentArray) => {
      if (/^:(.+)/.exec(segment)) {
        // these segments are path parameters
        uriRegex += '[\\w-]+';
      } else if (segment === '*') {
        // if the segment is a splat preceding a `/`, the slash is only
        // required when there are subsequent characters the path
        uriRegex =
          uriRegex.slice(-1) === '/'
            ? `${uriRegex.replace(/\/+$/, '')}(/?|[/?].*)`
            : '.*';
      } else {
        uriRegex += segment;
      }

      if (ix < segmentArray.length - 1) {
        uriRegex += '/';
      }
    });

  return new RegExp(
    // strip trailing slashes on prefix and prepend to uri
    `^${prefix.replace(/\/+$/, '')}${uriRegex ? `/${uriRegex}` : ''}/*$`
  );
};
