import { createSlice } from '@reduxjs/toolkit';
import {
  FAVORITE_STOPS_STORAGE_KEY,
  TRACKED_VEHICLE_CENTER_MAP_STORAGE_KEY,
} from '#/transit-tracker/constants';
import { getItem } from '#/shared/utils/storage';

const initialState = {
  // TODO: move this to shared node since its used outside this scope
  isLoading: false,
  focusedOnHighlightedStop: false,
  highlightedStop: null,
  arrivalsTimestamp: null,
  nearbyArrivals: null,
  nearbyLocationArrivals: null,
  vehicleRoute: null,
  vehicleRouteTimestamp: null,
  vehicleRouteError: false,
  vehicleRouteLoading: false,
  favoriteArrivals: getItem(FAVORITE_STOPS_STORAGE_KEY, []),
  favoriteStops: getItem(FAVORITE_STOPS_STORAGE_KEY, []),
  trackedVehicleCenterMap: getItem(
    TRACKED_VEHICLE_CENTER_MAP_STORAGE_KEY,
    true
  ),
  alertActive: false,
};

const transitTrackerSlice = createSlice({
  name: 'transitTracker',
  initialState,
  reducers: {
    setIsLoadingArrivals(state) {
      state.isLoadingArrivals = true;
    },
    trackedVehicleCenterMap(state, action) {
      state.trackedVehicleCenterMap = action.payload;
    },
    fetchArrivalsSuccess(state, action) {
      return {
        ...state,
        nearbyArrivals: action.payload.nearbyArrivals,
        favoriteArrivals: action.payload.favorites,
        arrivalsTimestamp: Date.now(),
        isLoading: false,
        isLoadingArrivals: false,
        isArrivalsError: false,
      };
    },
    fetchArrivalsError(state) {
      return {
        ...state,
        nearbyArrivals: [],
        favoriteArrivals: [],
        arrivalsTimestamp: null,
        isLoading: false,
        isLoadingArrivals: false,
        isArrivalsError: true,
      };
    },
    fetchLocationArrivalsSuccess(state, action) {
      return {
        ...state,
        nearbyLocationArrivals: action.payload.nearby,
        arrivalsTimestamp: Date.now(),
        isLoading: false,
        isLoadingArrivals: false,
        isArrivalsError: false,
      };
    },
    clearLocationArrivals(state) {
      return {
        ...state,
        nearbyLocationArrivals: null,
      };
    },
    resetUserArrivals(state) {
      return {
        ...state,
        nearbyArrivals: null,
      };
    },
    fetchStopsSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    fetchLocationStopsSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    fetchStopsEmpty(state) {
      return {
        ...state,
        nearbyArrivals: [],
        isLoading: false,
        isLoadingArrivals: false,
      };
    },
    fetchLocationStopsEmpty(state) {
      return {
        ...state,
        nearbyLocationArrivals: [],
        isLoading: false,
        isLoadingArrivals: false,
      };
    },
    setHighlightedStop(state, action) {
      state.highlightedStop = action.payload;
    },
    setFocusedOnHighlightedStop(state, action) {
      state.focusedOnHighlightedStop = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    fetchVehicleRouteSuccess(state, action) {
      return {
        ...state,
        vehicleRouteTimestamp: Date.now(),
        vehicleRoute: action.payload,
        vehicleRouteLoading: false,
        isLoading: false,
        isArrivalsError: false,
      };
    },
    fetchVehicleRouteEmpty(state) {
      return {
        ...state,
        vehicleRoute: null,
        vehicleRouteLoading: false,
        isLoading: false,
      };
    },
    vehicleRouteCleared(state) {
      return {
        ...state,
        vehicleRoute: null,
        vehicleRouteLoading: false,
        isLoading: false,
      };
    },
    favoriteStopsChanged(state, action) {
      state.favoriteStops = action.payload;
      state.favoriteArrivals = action.payload;
    },
  },
});

export const {
  trackedVehicleCenterMap,
  resetUserArrivals,
  fetchArrivalsError,
  fetchArrivalsSuccess,
  fetchLocationArrivalsSuccess,
  fetchLocationStopsSuccess,
  fetchLocationStopsEmpty,
  clearLocationArrivals,
  fetchStopsSuccess,
  fetchStopsEmpty,
  setHighlightedStop,
  setFocusedOnHighlightedStop,
  setIsLoading,
  setIsLoadingArrivals,
  fetchVehicleRouteSuccess,
  fetchVehicleRouteEmpty,
  vehicleRouteCleared,
  favoriteStopsChanged,
} = transitTrackerSlice.actions;
export default transitTrackerSlice.reducer;
