export default {
  // Enabled Map Views
  views: [
    {
      type: 'DEFAULT',
      text: 'Map View',
    },
    // TODO: Delete/uncomment the following block to disable the stylized map
    // {
    //   type: 'STYLIZED',
    //   text: 'Network View'
    // }
  ],
  // Default Map Center
  initLat: 45.52,
  initLon: -122.682,
  // Initial zoom level of the map for mobile and desktop
  initZoom: {
    mobile: 13,
    desktop: 15,
  },
  // The permitted boundaries for the map
  mapLimits: {
    top: 45.96,
    bottom: 44.68,
    left: -123.8,
    right: -121.5,
  },
  // Starting map height on mobile as percentage of the viewport
  minMapPercentageMobile: 50,
  // The max height on mobile as percentage of the viewport
  maxMapPercentageMobile: 80,
  // General padding when fitting the map to bounds
  padding: {
    mobile: 40,
    desktop: 30,
  },
  /* NOTE: it's intentional that the maxZoom of the map is 1 less than the maxZoom of the base layers
  If the Map's maxZoom == the base layer maxZoom, then leaflet renders that last layer as a
  gray / blank tile layer.  TriMet has good (aerial) data up to zoom level 20 (e.g. 6" rez) */
  maxZoom: 20,
  minZoom: 9,

  /* Value used as height and width for bounds generated around a point of interest
  used as the extent when zooming to that marker, in meters */
  poiBoundsExtent: 400,
  /* Styling for route geojson. Accepts leaflet geojson style options.
  NOTE: Color will be overridden by the app */
  routeStyle: {
    weight: 3,
  },
  // Interval at which realtime vehicles are updated
  realtimeVehicleRefreshInterval: 5000,
  // Determines if realtime vehicles are toggled on by default
  realtimeVehiclesToggled: true,
  // The minimum zoom at which all realtime vehicles are visible
  realtimeVehiclesMinZoom: 0,
  // Base layers for default map
  baseLayers: [
    {
      name: 'Streets',
      url: 'https://tiles.trimet.org/styles/trimet/{z}/{x}/{y}{r}.png',
      attribution:
        '&copy; <a target="#" href="https://openstreetmap.org/copyright">OpenStreetMap</a>',
      maxZoom: 21,
      hasRetinaSupport: true,
    },
    {
      name: 'Aerials',
      url: 'https://tiles.trimet.org/styles/trimet-satellite/{z}/{x}/{y}{r}.png',
      attribution:
        '&copy; <a target="#" href="https://www.oregonmetro.gov/rlis-live">Metro</a> | &copy; <a target="#" href="https://openstreetmap.org/copyright">OpenStreetMap</a>',
      maxZoom: 21,
      hasRetinaSupport: true,
    },
  ],
  /* route (image) overlay for showing all routes, toggled on and off based on mode
  note: BUS_LAYER, MAX_LAYER, etc... match layer names in map/constants.js */
  routeOverlays: [
    {
      name: 'BUS_LAYER',
      id: 'bus',
      url: 'https://maps.trimet.org/geoserver/wms/refelct',
      maxZoom: 21,
      zIndex: 1100,
      hasRetinaSupport: true,
    },
    {
      name: 'MAX_LAYER',
      id: 'max',
      url: 'https://maps.trimet.org/geoserver/wms/refelct',
      maxZoom: 21,
      zIndex: 1400,
      hasRetinaSupport: true,
    },
    {
      name: 'WES_LAYER',
      id: 'wes',
      url: 'https://maps.trimet.org/geoserver/wms/reflect',
      maxZoom: 21,
      zIndex: 1200,
      hasRetinaSupport: true,
    },
    {
      name: 'PSC_LAYER',
      layer: 'current:route',
      id: 'psc',
      url: 'https://maps.trimet.org/geoserver/wms/refelct',
      maxZoom: 21,
      zIndex: 1300,
      hasRetinaSupport: true,
    },
  ],
  vehicleRentalApi: {
    host: 'https://maps.trimet.org',
    path: '/otp_mod/vehicle_rental',
  },
  bikeRentalApi: {
    host: 'https://maps.trimet.org',
    path: '/otp_mod/bike_rental',
  },
  zipcarApi: {
    host: 'https://maps.trimet.org',
    path: '/carshare/zipcar/data.json',
  },
  parkAndRideApi: {
    host: 'https://maps.trimet.org',
    path: '/otp_mod/park_and_ride',
  },
  mlMapBaseLayers: [
    {
      url: 'https://tiles-st.trimet.org/styles/trimet/style.json',
      name: 'streets',
    },
    {
      url: 'https://tiles-st.trimet.org/styles/trimet-satellite/style.json',
      name: 'aerials',
    },
    {
      url: 'https://tiles-st.trimet.org/styles/trimet-routes/style.json',
      name: 'routes',
    },
    {
      url: 'https://tiles-st.trimet.org/styles/trimet-satellite-routes/style.json',
      name: 'routes + aerials',
    },
    {
      url: 'https://tiles-st.trimet.org/styles/3d/style.json',
      name: 'three dimensional',
    },
    {
      url: 'https://tiles-st.trimet.org/styles/3d-routes/style.json',
      name: 'routes + three dimensional',
    },
  ],
};
