import React from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash-es';
import * as Styled from './styled';

/**
 * Wrapping the `react-hot-toast` Toaster component in this element ensures
 * toasts that it generates are read by a screen reader. A wrapper is used
 * because the `Toaster` api doesn't provide a way to add DOM attributes
 * directly to the element that the toast instances are injected to
 */
export default function ToasterA11yWrapper({ children }) {
  return (
    <Styled.ToasterWrapper
      aria-live="polite"
      // `id` is set make it easier to identify the node where toasts
      // will be injected when inspecting the DOM
      id={uniqueId('toast-wrapper-')}
    >
      {children}
    </Styled.ToasterWrapper>
  );
}

ToasterA11yWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
