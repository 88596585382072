import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { flatten } from 'flat';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
  ENGLISH_LOCALE_CODE,
  LANGUAGE_LOOKUP,
  LOCALE_QUERY_STRING_KEY,
  SELECTED_LOCALE_STORAGE_KEY,
} from '#/shared/constants';
import SoftToast from '#/components/SoftToast';
import { getItem, storeItem } from '#/shared/utils/storage';
import { INITIAL_LOCALE, setSelectedLanguage } from '#/user/slice';
import { clearQueryParam } from '#/shared/utils/navigate';
import { getStringsSubdomain } from '#/shared/utils/url';
import { enStrings } from '../../../../static/intl';

const STRINGS_STORAGE_SUFFIX = 'Strings';

function IntlProvider({ children }) {
  // SSR will be done in English since those strings live in the repo
  // and translated versions are hosted by Smartling
  const enMessages = useMemo(() => flatten(enStrings), []);
  const { selectedLocale } = useSelector(s => s.user);
  const [providerLocale, setProviderLocale] = useState(ENGLISH_LOCALE_CODE);

  // A ref is used here instead of state because changes to this lookup
  // should not trigger a re-render, but changes to it need to be
  // preserved across renders
  const messagesLookupRef = useRef({ [ENGLISH_LOCALE_CODE]: enMessages });
  const languageToastIdRef = useRef(null);
  const languageHasChangedRef = useRef(false);
  const providerLocaleRef = useRef(ENGLISH_LOCALE_CODE);

  const [, setStringsId] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (providerLocaleRef.current === selectedLocale) {
      return undefined;
    }

    const messagesLookup = messagesLookupRef.current;

    // this wrapper ensures that no more than one toast pertaining the
    // language selection is visible at on time
    const showLanguageToast = (...toastDotCustomArgs) => {
      toast.remove(languageToastIdRef.current);
      languageToastIdRef.current = toast.custom(...toastDotCustomArgs);
    };

    const changeLanguage = () => {
      // keep the providerLocaleRef in sync with the selected locale
      providerLocaleRef.current = selectedLocale;
      setProviderLocale(selectedLocale);
      storeItem(SELECTED_LOCALE_STORAGE_KEY, selectedLocale);

      // once the language is successfully changed the query param
      // pertaining to locale is no longer relevant and can become
      // mismatched with the displayed language if not cleared
      clearQueryParam(LOCALE_QUERY_STRING_KEY);

      // don't show the language change message when the change is initiated
      // by a value read from local storage or a querystring param
      if (languageHasChangedRef.current || selectedLocale !== INITIAL_LOCALE)
        showLanguageToast(
          t => (
            <SoftToast
              messageId="language-picker.language-changed"
              type="info"
              toastOptions={t}
            />
          ),
          { duration: 8000 }
        );

      languageHasChangedRef.current = true;
    };

    if (selectedLocale in messagesLookup) {
      changeLanguage();
      return undefined;
    }

    let cancelLanguageChange = false;
    const stringsStorageKey = selectedLocale + STRINGS_STORAGE_SUFFIX;
    const storedStrings = getItem(stringsStorageKey);

    // if the requested strings are present in localStorage use them
    // so the app is immediately translated, but allow the request to
    // proceed to get the latest version
    if (storedStrings) {
      messagesLookup[selectedLocale] = storedStrings;
      changeLanguage();
      cancelLanguageChange = true;
    }

    const subdomain = getStringsSubdomain(selectedLocale);

    // non-english versions of `strings.json` served on Smartling's GDN
    // are available under locale code subdomains of trimet.org
    fetch(`https://${subdomain}.trimet.org/home/intl/strings.json`, {
      method: 'GET',
      // ensure that the strings file is not cached by the browser since
      // it can be updated at any time with new translations via Smartling
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
      },
    })
      .then(response => response.json())
      .then(stringsJson => {
        const flattenedStrings = flatten(stringsJson);
        messagesLookup[selectedLocale] = flattenedStrings;
        storeItem(stringsStorageKey, flattenedStrings);

        // ensure the most recent language selection is the one displayed
        // by preventing a request from changing the language if another
        // is initiated before it is fulfilled
        if (!cancelLanguageChange) {
          changeLanguage();
        }

        // this state node is updated to ensure the latest version of
        // the strings are rendered when the language is not changed
        setStringsId(`${selectedLocale}-${Date.now()}`);
      })
      .catch(error => {
        if (!storedStrings) {
          showLanguageToast(
            t => (
              <SoftToast
                messageId="language-picker.failed-to-fetch-strings"
                messageValueIdLookup={{
                  language: LANGUAGE_LOOKUP[selectedLocale].nameIntlId,
                }}
                type="error"
                toastOptions={t}
              />
            ),
            { duration: Infinity }
          );

          // change back to previously selected language since we
          // don't have strings for the latest selection
          dispatch(setSelectedLanguage(providerLocaleRef.current));
        }

        console.error(
          'Error fetching JSON file containing user-facing strings:',
          error
        );
      });

    return () => {
      cancelLanguageChange = true;
    };
  }, [dispatch, selectedLocale]);

  return (
    <ReactIntlProvider
      locale={providerLocale}
      defaultLocale={ENGLISH_LOCALE_CODE}
      messages={messagesLookupRef.current[providerLocale]}
    >
      {children}
    </ReactIntlProvider>
  );
}

IntlProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default IntlProvider;
