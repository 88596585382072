export const FAVORITE_STOPS_STORAGE_KEY = 'favoriteStops';
export const TRACKED_VEHICLE_CENTER_MAP_STORAGE_KEY =
  'TRACKED_VEHICLE_CENTER_MAP_STORAGE_KEY';

export const BUS_TYPE = 'B';
export const RAIL_TYPE = 'R';
export const STREETCAR_SUB_TYPE = 'Streetcar';
export const WES_SUB_TYPE = 'Commuter Rail';
export const MAX_SUB_TYPE = 'Light Rail';
export const SHUTTLE_SUB_TYPE = 'Shuttle';
export const BRT_SUB_TYPE = 'BRT'; // Bus Rapid Transit

export const AERIAL_TRAM_ROUTE_ID = 208;

export const ALERTS_POLLING_INTERVAL = 120000;
export const SKELETON_INTERVAL = 1500;

export const ROUTE_COLOR_MAPPING = {
  100: 1,
  200: 2,
  90: 3,
  290: 4,
  190: 5,
} as const;
