import styled from 'styled-components';

const CircleBase = styled.circle.attrs({
  r: 15,
  cx: 25,
  cy: 25,
  strokeWidth: '.1em',
  fill: 'transparent',
})``;

export const BackCircle = styled(CircleBase).attrs(({ backCircleColor }) => ({
  stroke: backCircleColor,
  strokeDasharray: 100,
  strokeDashoffset: 0,
}))``;

export const FrontCircle = styled(CircleBase).attrs(({ clockwise }) => ({
  animationName: `${clockwise ? '' : 'counter'}clockwiseFillStroke`,
  fromDashOffset: clockwise ? 100 : 0,
  toDashOffset: clockwise ? 0 : 100,
}))`
  // the animation name must be changed when the clockwise prop is changed
  // for the keyframes to be recalculated at that time
  @keyframes ${({ animationName }) => animationName} {
    from {
      stroke-dashoffset: ${({ fromDashOffset }) => fromDashOffset};
    }
    to {
      stroke-dashoffset: ${({ toDashOffset }) => toDashOffset};
    }
  }

  stroke: ${({ frontCircleColor }) => frontCircleColor};
  stroke-dasharray: 100;
  stroke-dashoffset: ${({ fromDashOffset }) => fromDashOffset};
  animation-name: ${({ animationName }) => animationName};
  animation-duration: ${({ duration }) => duration}s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
`;

export const SvgWrapper = styled.svg.attrs({
  width: 50,
  height: 50,
})``;

export const CircleGroup = styled.g.attrs({
  transform: 'rotate(-90 25 25)',
})``;
