import coreUtils from '@opentripplanner/core-utils';
import {
  DEFAULT_ROUTE_COLOR,
  WALK_MODE,
  BICYCLE_MODE,
  BICYCLE_RENT_MODE,
  BUS_FX_MODE,
  CAR_HAIL_MODE,
  CAR_MODE,
  CAR_RENT_MODE,
  MICROMOBILITY_MODE,
  MICROMOBILITY_RENT_MODE,
  PARK_AND_RIDE_MODE,
  PORTLAND_STREETCAR_AGENCY,
  STREETCAR_MODE,
} from '#/shared/constants';

const { isTransit } = coreUtils.itinerary;

/**
 * the GTFS spec indicates that the route color should not have a leading hash
 * symbol, so add one if the routeColor exists and doesn't start with a hash
 * symbol.
 */
export const toSafeRouteColor = routeColor => {
  return (
    routeColor && (routeColor.startsWith('#') ? routeColor : `#${routeColor}`)
  );
};

/**
 * Get color code for transportation mode or transit route
 *
 * @param mode {string} Transportation mode as defined in otp-ui and otp engine
 * @param [routeColor] {string} Hex color code for route from GTFS, only needed
 * when supplied mode is a form on transit
 * @param [defaultColor] {string} Hex color code that is used if there is no
 * handling for the supplied mode and a valid `routeColor` is not supplied
 * @return {string} Hex color code
 */
export const toModeColor = (mode, routeColor = null, defaultColor = null) => {
  switch (mode) {
    case WALK_MODE:
      return '#484848';
    case BICYCLE_MODE:
    case BICYCLE_RENT_MODE:
      return '#ed5b14';
    case CAR_MODE:
    case CAR_HAIL_MODE:
    case CAR_RENT_MODE:
    case PARK_AND_RIDE_MODE:
      return '#808080';
    case MICROMOBILITY_MODE:
    case MICROMOBILITY_RENT_MODE:
      return '#17c717';
    default:
      return (
        toSafeRouteColor(routeColor) || defaultColor || DEFAULT_ROUTE_COLOR
      );
  }
};

/**
 * Returns attributes for styling an SVG line element that change dynamically
 * based on the supplied mode and routeColor
 */
export const toModeLineStyle = (mode, routeColor) => {
  let modeLineStyle = {};

  if (mode === WALK_MODE) {
    modeLineStyle = {
      strokeWidth: 4,
      strokeDasharray: '0 7.5',
      strokeLinecap: 'round',
    };
  } else if (
    [
      BICYCLE_MODE,
      BICYCLE_RENT_MODE,
      CAR_MODE,
      CAR_HAIL_MODE,
      CAR_RENT_MODE,
      PARK_AND_RIDE_MODE,
      MICROMOBILITY_MODE,
      MICROMOBILITY_RENT_MODE,
    ].includes(mode)
  ) {
    modeLineStyle = {
      strokeWidth: 4,
      strokeDasharray: '8 4',
    };
  }

  return {
    strokeWidth: 8,
    stroke: toModeColor(mode, routeColor),
    ...modeLineStyle,
  };
};

/**
 * Within OpenTripPlanner Portland Streetcar's mode value is "TRAM", this is
 * the same code given to MAX so this function uses other attributes in the leg
 * object to determine if it is a streetcar leg
 *
 * @param leg {Object} OpenTripPlanner itinerary leg
 */
export const modeFromLeg = leg => {
  // TODO: Once we get BUS_FX_MODE flowing through OTP, can remove this if statement.
  if (leg.route === 'FX2') {
    return BUS_FX_MODE;
  }
  return leg.agencyId === PORTLAND_STREETCAR_AGENCY ? STREETCAR_MODE : leg.mode;
};

export const modesContainTransit = legs =>
  legs.some(leg => isTransit(leg.mode));
