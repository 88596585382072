import { createSlice } from '@reduxjs/toolkit';
import queryString from 'query-string';
import { LIGHT_THEME } from '#/user/themes';
import {
  ENGLISH_LOCALE_CODE,
  LOCALE_QUERY_STRING_KEY,
  MOBILE_MAX_BREAK_NUMBER,
  SELECTED_LOCALE_STORAGE_KEY,
} from '#/shared/constants';
import { getItem } from '#/shared/utils/storage';

// for the initial locale the querystring parameter takes precedence,
// then the local storage value and the fallback is English
export const INITIAL_LOCALE =
  queryString.parse(
    typeof window !== 'undefined' ? window.location.search : ''
  )[LOCALE_QUERY_STRING_KEY] ||
  getItem(SELECTED_LOCALE_STORAGE_KEY, ENGLISH_LOCALE_CODE);

const initialState = {
  isLayerSettingsOpened: false,
  sidePanelIsMinimized: false,
  mobileMode: false,
  theme: LIGHT_THEME,
  alertBannerIsVisible: false,
  globalAlertTimestamp: null,
  selectedLocale: INITIAL_LOCALE,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSidePanelIsMinimized(state, action) {
      state.sidePanelIsMinimized = action.payload;
    },
    setTheme(state, action) {
      state.theme = action.payload;
    },
    viewportWidthChanged(state, action) {
      state.mobileMode = action.payload <= MOBILE_MAX_BREAK_NUMBER;
    },
    setAlertBannerVisibility(state, action) {
      state.alertBannerIsVisible = action.payload;
    },
    setGlobalAlertTimestamp(state, action) {
      state.globalAlertTimestamp = action.payload;
    },
    setLayerSettings(state, action) {
      state.isLayerSettingsOpened = action.payload;
    },
    setSelectedLanguage(state, action) {
      state.selectedLocale = action.payload;
    },
  },
});

export const {
  setSidePanelIsMinimized,
  setTheme,
  viewportWidthChanged,
  setAlertBannerVisibility,
  setGlobalAlertTimestamp,
  setLayerSettings,
  setSelectedLanguage,
} = userSlice.actions;
export default userSlice.reducer;
